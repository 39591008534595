<template>
    <div>
        <v-text-field
            :label="label"
            :readonly="readonly"
            :dense="dense"
            v-model="modelData"
            rounded
            filled
            :rules='[
                value => value?/^([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])\2(\d{4})$/.test(value)||"Formato debe ser tipo 31/01/2021":true,
                ...rules
            ]'
            append-icon="mdi-calendar"
            @click:append="abreDatePickerMenu"
        />
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
            >

                <v-date-picker
                    v-model="date"
                    scrollable
                >
                    <v-btn
                        small
                        color="primary"
                        @click="setHoy"
                    >
                        Hoy
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        small
                        icon
                        color="error"
                        @click="modal = false"
                    >
                        <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                    <v-btn
                        small
                        color="success"
                        @click="guardaFecha(date)"
                    >
                        <v-icon>mdi-check-bold</v-icon>
                    </v-btn>
                </v-date-picker>
            </v-dialog>
    </div>
</template>

<script>
//import {DateTime}  from 'luxon'
export default {
    props:{
        label:String,
        model:String,
        //requerido:Boolean,
        readonly: Boolean,
        dense: Boolean,
        rules:Array
    },
    data:()=>({
        modal:false,
        date:'',
        //rules:[],
    }),
    methods:{
        abreDatePickerMenu(){
            if (!this.modelData){
                this.setHoy()
            } else {
                //this.date = DateTime.fromFormat(this.model, 'dd/MM/yyyy').toFormat('yyyy-MM-dd')
                this.date = this.moment(this.model, "DD/MM/YYYY").format("YYYY-MM-DD")
            }
            this.modal = true
        },
        guardaFecha(v){
            //this.modelData = DateTime.fromISO(v).toFormat('dd/MM/yyyy')
            this.modelData = this.moment(v).format('DD/MM/YYYY')
            this.$emit('fechaActualizada')
            this.modal=false
        },
        setHoy(){
            //this.date = this.moment(new Date()).format('YYYY/MM/DD')
            this.date=this.moment(new Date()).format('YYYY-MM-DD')
            //this.date = DateTime.now().toFormat('yyyy-MM-dd')
        }
    },
    computed:{
        modelData:{
            get(){return this.model},
            set(v){
                this.$emit('update:model', v)
            },
        }
    },
    watch:{
        requerido(v){
            if(v){
                this.rules = [value => !!value || 'Requerido.']
            }
        }
    },
}
</script>

<style>
</style>